import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner';
import { Assesments } from '../components/Assesments.tsx';
import { Toast } from 'primereact/toast';

export const GET_ASSESMENTS = gql`
query AssesmentsQ ($candidateId:Int){
  assesments (candidateId: $candidateId){
    id
    name
    description
    completed
    user { id code:id name email  }
    candidate { id code:id name email }
    relationship { id code:id name  color }
    status { id code:id name  color }
  }
}
`
export const AssesmentsPage = () => {

  const location = useLocation();
  const toast = React.useRef<Toast>(null);

  const SearchParam = new URLSearchParams(location.search)
  const candidateIdStr = SearchParam.get('candidateId');
  
  let candidateId
  if(candidateIdStr && isNaN(candidateIdStr)) {
    candidateId = 0
  } else {
    candidateId = parseInt(candidateIdStr);
  }

  const {loading, error, data} = useQuery(GET_ASSESMENTS, {
    variables: {
      candidateId: candidateId
    }
  });

  /*
  const onRowSelect = (event) => {
    const pillar = event?.data?.pillar || event?.value?.pillar;
    const attribute = event?.data?.attribute || event?.value?.attribute;
    if(toast && toast.current)
      toast.current.show({ 
        severity: 'info'
      , summary: 'Row Selected'
      , detail: `${pillar?.name ? ("Pilar:"+pillar?.name):""} || ${attribute?.name ? ("Atributo:"+attribute?.name):""} `
      , life: 3000 
    });
  };
*/
  return (
    <div><p>Listado de las evaluaciones a las cuales tiene acceso</p>
     <Toast ref={toast} />
      <QueryResult error={error} loading={loading} data={data} >
        <Assesments assesments={data?.assesments}/>
      </QueryResult>
    </div>
  );
};

export default withAuthenticationRequired(AssesmentsPage, {
  onRedirecting: () => <SpinnerContainer />,
});