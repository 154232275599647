import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery, gql } from '@apollo/client';

import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner/index.js';

import { 
  RelationshipSelectButton
 } from '../components/RelationshipToggle.tsx';

import { 
  AssesmentSummary
, AssesmentPillarSummary 
, AssesmentAttributeSummary
, AssesmentDetail
, AssesmentRadar
} from '../components/AssesmentSummary.tsx';

import { Toast } from 'primereact/toast';

import { LicenseKeyCard }  from "../components/LicenseKeyDialog.tsx"
        

export const GET_LICENSEKEYS = gql`
query licenseKey($licenseKeyId:Int) {
  license_key (id:$licenseKeyId){
    licenseKey
    id
    description
    createdBy {
      name
      email
    }
    candidate {
      id
      code:id
      name
      email
    }
    status { id code:id name  color }
    assesments {
      id
      name
      description
      completed
      user { id code:id email name }
      candidate { id code:id email name }
      relationship { id code:id name color minimum maximum}
      status { id code:id name  color }
    }
  }
  assesment_summary (licenseKeyId: $licenseKeyId){
    candidateId
    pillar {
      id name
    }    
    attribute {
      id name
    }
    question {
      id name      
    }    
    Ninguno
    Yo
    Jefe
    Par
    Colaborador
    Familia
    Demas    
    value
  }
}
`




export const LicensesKeyPage = () => {
  const {licenseKeyId}=useParams();
  const toast = React.useRef<Toast>(null);
  const [pillar, setPillar] = React.useState(null);  

  const {loading, error, data} = useQuery(GET_LICENSEKEYS, {
    variables: {
      licenseKeyId: Number(licenseKeyId)
    }
  });

  let assesment_summary_overall
  let relationships : string[] = []
  let assesment_summary_by_Pillar
  let assesment_summary_by_Attribute  
  if(data?.assesment_summary) {

    //summarize by Attribute
    assesment_summary_by_Attribute = data
    .assesment_summary
    .filter( r => r.attribute && !r.question);

    //summarize by Pillar
    assesment_summary_by_Pillar = data
    .assesment_summary
    .filter(r => r.pillar && !r.attribute)

    //summarize by Pillar
    assesment_summary_overall = data
    .assesment_summary
    .filter(r => !r.pillar )[0]

    if(assesment_summary_overall) {
      if(Number(assesment_summary_overall?.Yo) !== 0) relationships.push("Yo")
      if(Number(assesment_summary_overall?.Demas) !== 0) relationships.push("Demas")
      if(Number(assesment_summary_overall?.Jefe) !== 0) relationships.push("Jefe")
      if(Number(assesment_summary_overall?.Par) !== 0) relationships.push("Par")
      if(Number(assesment_summary_overall?.Colaborador) !== 0) relationships.push("Colaborador")
      if(Number(assesment_summary_overall?.Familia) !== 0) relationships.push("Familia")
    }
  }

  const onRowSelect = (event) => {
    const pillar = event?.data?.pillar || event?.value?.pillar;
    const attribute = event?.data?.attribute || event?.value?.attribute;
    setPillar(pillar)
    if(toast && toast.current)
      toast.current.show({ 
        severity: 'info'
      , summary: 'Row Selected'
      , detail: `${pillar?.name ? ("Pilar:"+pillar?.name):""} || ${attribute?.name ? ("Atributo:"+attribute?.name):""} `
      , life: 3000 
    });
  };

  const [selectedRelationships, setSelectedRelationships] = useState<string[]>(relationships);
  const onSelectedRelationshipChange = ( relationships:string[]) => {
        setSelectedRelationships(relationships);
  };  

  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
    <Toast ref={toast} />  
    <div>
      <h1>Evaluación</h1>
      <QueryResult error={error} loading={loading} data={data} >
        <LicenseKeyCard licenseKey={data?.license_key[0]}/>


         <div>
          <div style={{width:900}}>
           <p>Como coachee, debera ingresar el coreo electronicóo y el nombre de las personas que le proveeran retroalimentación (evaluadores). Deberá especificar la relación que el evaluador tiene con usted</p>
           <p>Una ves que complete la lista, podrá enviarles una notificación por correo elecronico el cual les indicará su participación en esta evaluación. La notificación explicará el proceso junto con las instruciones de como utilizar esta herramienta</p>
          </div>          
         </div>

         <div>
          <AssesmentSummary assesment_summary_by_pillar={assesment_summary_by_Pillar}/> 

          <RelationshipSelectButton
            relationships={relationships}
            selectedRelationships={selectedRelationships || []}
            onChange={onSelectedRelationshipChange}
          /> 

          <div className="flex flex-wrap justify-content-center">          
           <div className="flex flex-wrap flex-grow-1">
             <AssesmentPillarSummary
               assesment_summary_by_pillar={assesment_summary_by_Pillar}
               showRelationships={selectedRelationships || []}
               onRowSelect={onRowSelect}
            />
           </div>
           <div className="flex flex-wrap flex-grow-1">
             <AssesmentRadar 
               assesment_summary_by_pillar={assesment_summary_by_Pillar}
               showRelationships={selectedRelationships || []}
             />
           </div>          
          </div>
          
         <div className="flex flex-wrap">
         <AssesmentAttributeSummary 
            assesment_summary={assesment_summary_by_Attribute} 
            showRelationships={selectedRelationships || []}
            onRowSelect={onRowSelect} 
            pillar={pillar}
          /> 
          <AssesmentDetail
            assesment_summary={data?.assesment_summary.filter(r=>r.question)} 
            showRelationships={selectedRelationships || []}
            onRowSelect={onRowSelect} 
            pillar={pillar}
          />
         </div>
         </div>



      </QueryResult>
    </div>
    </div>
  );
};

export default withAuthenticationRequired(LicensesKeyPage, {
  onRedirecting: () => <SpinnerContainer />,
});