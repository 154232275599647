import React from 'react';
import { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { Chart } from 'primereact/chart';
import { isArray } from 'chart.js/helpers';

export const PillarCard = ({pillar, assesment_summary_by_pillar}) => {
    return(
        <div className="flex-grow-1">
        <Card  title={pillar.name} subTitle="Escala: Desarrollo o competencia">

<div className="flex flex-grow-1">
<Image src={`/i/pillar${pillar.name.replaceAll(" ", "")}.png`} alt="Image" width="80px" height="80px"
style={{
    margin: '5px'
}}/>
        <div style={{
            margin: '5px'
        }}>
            <div style={{
              background:'DarkBlue'
            , width: '80px'
            , height: '80px'               
            , display: 'flex'
            , alignItems: 'center'
            , justifyContent: 'center'
            , color: 'white'            
            }}>{assesment_summary_by_pillar.Yo.toFixed(2)}</div>
            <div style={{
              background:'DarkGray'
            , width: '80px'
            , display: 'flex'
            , alignItems: 'center'
            , justifyContent: 'center'
            }}
            >Yo</div>
        </div>

        <div style={{
            margin: '5px'
        }}>
            <div style={{
            background:'LightGray'
            , width: '80px'
            , height: '80px'               
            , display: 'flex'
            , alignItems: 'center'
            , justifyContent: 'center'
            , color: 'Black'        
            }}>{assesment_summary_by_pillar.Demas.toFixed(2)}</div>
            <div style={{
              background:'DarkGray'
            , width: '80px'
            , display: 'flex'
            , alignItems: 'center'
            , justifyContent: 'center'
            }}
            >Otros</div>
        </div>

        <div style={{
            margin: '5px'
        }}>
            <table><tbody>
            <tr><td width='220px'>Jefe</td><td width='0px'>{assesment_summary_by_pillar.Jefe.toFixed(2)}</td></tr>
            <tr><td width='220px'>Par</td><td width='0px'>{assesment_summary_by_pillar.Par.toFixed(2)}</td></tr>
            <tr><td width='220px'>Colaborador</td><td width='0px'>{assesment_summary_by_pillar.Colaborador.toFixed(2)}</td></tr>
            <tr><td width='220px'>Familia</td><td width='0px'>{assesment_summary_by_pillar.Familia.toFixed(2)}</td></tr>
            </tbody></table>
        </div>
</div>

        </Card>
        </div>
    )
}    

export const AssesmentSummary = ({assesment_summary_by_pillar}) => {
    const pillarsArray = Object
        .keys(assesment_summary_by_pillar)
        .map(p => assesment_summary_by_pillar[p] )

    return(
    <div className="flex flex-wrap justify-content-center">
        {pillarsArray.map(p=> <PillarCard key={p.pillar.name} pillar={p.pillar} assesment_summary_by_pillar = {p}/>)}        
    </div>
    )
}

const getSeverity = (value) => {
    return (
      value >= 8 ? 'success'
    : value >= 6 ? 'info'
    : value >= 4 ? 'warning'
    :              'danger'
    );
};

const TagYo          = ({Yo})          => <Tag value={(Yo||0         ).toFixed(2)} severity={getSeverity(Yo)}          />;
const TagValue       = ({Jefe})        => <Tag value={(Jefe||0       ).toFixed(2)} severity={getSeverity(Jefe)}        />;
const TagPar         = ({Par})         => <Tag value={(Par||0        ).toFixed(2)} severity={getSeverity(Par)}         />;
const TagFamilia     = ({Familia})     => <Tag value={(Familia||0    ).toFixed(2)} severity={getSeverity(Familia)}     />;
const TagColaborador = ({Colaborador}) => <Tag value={(Colaborador||0).toFixed(2)} severity={getSeverity(Colaborador)} />;
const TagDemas       = ({Demas})       => <Tag value={(Demas||0      ).toFixed(2)} severity={getSeverity(Demas)}       />;
const Tags = {
  "Yo"          : TagYo
, "Jefe"        : TagValue
, "Par"         : TagPar
, "Familia"     : TagFamilia
, "Colaborador" : TagColaborador
, "Demas"       : TagDemas
};


const RatingYo = ({Yo}) => <Rating value={Yo} cancel={false} stars={10}/>;

function calculateAveragesOverall(assesment_summary_by_pillar) {
    const averages =  {Yo:0,Jefe:0,Par:0, Familia:0, Colaborador:0, Demas:0};
    for(let p in assesment_summary_by_pillar) {        
        for(let average in averages) {
            averages[average]+=assesment_summary_by_pillar[p][average];
        }
    };
    for(let average in averages) {
        averages[average]/=Object.keys(assesment_summary_by_pillar).length;
    }
    return averages;
}

function calculateAveragesPillar (assesment_summary, pillarName) {
    const averages =  {Yo:0,Jefe:0,Par:0, Familia:0, Colaborador:0, Demas:0};

    let currentPillar
    if(pillarName)
        currentPillar = assesment_summary.filter(e=>e.pillar.name === pillarName);
    else
        currentPillar = assesment_summary;
        
    if(isArray(currentPillar)) {
        currentPillar.forEach( attribute => {
            for(let average in averages) {
                averages[average]+=attribute[average];
            }
        })
        for(let average in averages) {
            averages[average]/=currentPillar.length;
        }
    }
    return averages;
}

const calculateAveragesAttribute = (assesment_summary, pillarName,attributeName) => {        
    const averages =  {Yo:0,Jefe:0,Par:0, Familia:0, Colaborador:0, Demas:0};
    let currentAttribute = assesment_summary.filter(e=>(e.pillar.name === pillarName || (!pillarName)));

    if(attributeName)
        currentAttribute = currentAttribute.filter(e=>e.attribute.name === attributeName);

    if(isArray(currentAttribute)) {
        currentAttribute.forEach( question => {
            for(let average in averages) {
                averages[average]+=question[average];
            }
        })
        for(let average in averages) {
            averages[average]/=currentAttribute.length;
        }
    }

    return averages;
}

export const AssesmentPillarSummary = ({assesment_summary_by_pillar, showRelationships, onRowSelect}) => {
    const pillarsArray = [];
    
    const [selectedPillar, setSelectedPillar] = useState(null);
    const onSelectedPillar = (e) => {
        setSelectedPillar(e.value);
        onRowSelect(e)
    }

    const averages =  calculateAveragesOverall(assesment_summary_by_pillar);

    for(let p in assesment_summary_by_pillar) {        
        pillarsArray.push(assesment_summary_by_pillar[p]);
    };

    const header = (
        <span className="text-xl text-900 font-bold">Pilares</span>        
    );

    //const footer = `Promedio ${averages.Yo.toFixed(2)}.`

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Promedio:" frozen/>
                {showRelationships.map(r => <Column key={r} footer = {<TagValue Jefe={averages[r]}/>} /> )}
                <Column />
            </Row>
        </ColumnGroup>
    );
 
    return(
        <DataTable value={pillarsArray} size="small"
            header={header}
            footerColumnGroup={footerGroup}
            selectionMode="single" selection={selectedPillar} onSelectionChange={onSelectedPillar}
            
            tableStyle={{ minWidth: '50rem' }}
        >
            <Column style={{ width: '25%' }} field="pillar.name" header="Pilar"   frozen />
            { showRelationships.map( r => <Column key={r} style={{ width: '5%' }} field={r} header={r} body={Tags[r]}/> ) }
            <Column style={{ width: '50%' }} field="Yo" header="Coachee"   body={RatingYo}/>
        </DataTable>
    ) //onRowSelect={onRowSelect}
}

export const AssesmentAttributeSummary = ({assesment_summary, showRelationships, onRowSelect, pillar}) => {

    const dataTableValue = assesment_summary.filter(e=>(e.pillar.name === pillar?.name || (!pillar?.name))); 

    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        if(pillar) {
            const pillarFound = expandedRows.findIndex(e=> Number(e.pillar.id) === Number(pillar.id));
            if(-1 === pillarFound) {
                setExpandedRows([...expandedRows, dataTableValue[0]]);
            }
        }        
    },[pillar, expandedRows, dataTableValue]);

    const header = (
        <span className="text-xl text-900 font-bold">Atributos por Pilar {pillar?.name}</span>
    );

    const headerTemplate = ({pillar}) => 
        <span className="font-bold ">{pillar.name}</span>
    ;

    const footerTemplate = ({pillar}) => {
        const pillarAverage = calculateAveragesPillar(assesment_summary,pillar.name);
        return (
            <React.Fragment>
                <td className="flex justify-content-end font-bold line-height-4">Promedio para el pilar {pillar.name}</td>
                {showRelationships.map(r =>  <td key={r}><TagValue Jefe={pillarAverage[r]}/></td>)}
            </React.Fragment>
        );//<td><RatingYo Yo={pillarAverage}/></td>
    };

    const overallAverage = calculateAveragesPillar(assesment_summary,"");

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Promedio:" frozen/>
                {showRelationships.map(r => <Column key={r} footer = {<TagValue Jefe={overallAverage[r]}/>} /> )}
            </Row>
        </ColumnGroup>
    );//<Column colSpan={1} />

    const setExpandedRowsEvent = (data) => {
        console.log("@",data);
        setExpandedRows(data)
    }

    return(        
        <DataTable value={dataTableValue} size="small"
        header={header}         
        footerColumnGroup={footerGroup}
        selectionMode="single" onRowSelect={onRowSelect} 
        rowGroupMode="subheader" groupRowsBy="pillar.name" rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}
        expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRowsEvent(e.data)}
        tableStyle={{ minWidth: '50rem' }}
        className="flex-grow-1"
        >
            <Column style={{ width: '25%' }} field="attribute.name" header="Atributo" frozen/>
            {showRelationships.map(r => <Column key={r} style={{ width: '10%' }} field={r} header={r} body={Tags[r]}/> )}
            
        </DataTable>
    )//<Column style={{ width: '50%' }} field="Yo" header="Coachee" body={RatingYo}/>
 
}

export const AssesmentDetail = ({assesment_summary, showRelationships, onRowSelect, pillar}) => {

    const relationships : string[] = [
        'Yo',
        'Demas',
        'Jefe',
        'Par',
        'Colaborador',
        'Familia',
    ].filter( fRel => showRelationships.some( sRel => sRel === fRel));

    const header = (
        <span className="text-xl text-900 font-bold">Preguntas por Pilar {pillar?.name}</span>
    );

    const headerTemplate = ({attribute}) => 
        <span className="font-bold ">{attribute.name}</span>
    ;

    const footerTemplate = ({attribute}) => {
        const attributeAverage = calculateAveragesAttribute(assesment_summary,pillar?.name,attribute.name);
        return (
            <React.Fragment>
                <td className="flex justify-content-end font-bold line-height-4">Promedio para el atributo {attribute.name}</td>
                {relationships.map(r => <td key={r}><TagValue Jefe={attributeAverage[r]}/></td>)}                
            </React.Fragment>
        );
    };

    const attributeOverallAverage = calculateAveragesAttribute(assesment_summary,pillar?.name,"");
    const footerGroup = (
        <ColumnGroup>
            <Row >
                <Column footer="Promedio:" />
                {relationships.map(r => <Column key={r} footer = {<TagValue Jefe={attributeOverallAverage[r]}/>} />)}
                <Column colSpan={1} />
            </Row>
        </ColumnGroup>
    );
    
    return(
        <DataTable value={assesment_summary.filter(e=>(e.pillar.name === pillar?.name || (!pillar?.name)))} size="small"
        header={header}
        footerColumnGroup={footerGroup}
        selectionMode="single" onRowSelect={onRowSelect} 
        rowGroupMode="subheader" groupRowsBy="attribute.name"
        rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}
        className="flex-grow-1"
        tableStyle={{ minWidth: '50rem' }}
        >
            <Column style={{ width: '75%' }} field="question.name" header="Atributo"/>
            {relationships.map(r => <Column key={r} style={{ width: '25%' }} field={r} header={r} body = {Tags[r]} />)}
        </DataTable>
    )
}

export const AssesmentRadar = ({assesment_summary_by_pillar, showRelationships} ) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});


    useEffect(() => {
        
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');

        const labels = [];
        const datasets = [];
        const relationships = {
          "Yo":'--yellow-400'
        , "Jefe":'--pink-400'
        , "Par":'--red-400'
        , "Familia":'--orange-400'
        , "Colaborador":'--teal-400'
        , "Demas":'--bluegray-400'
        }

        for(let s in assesment_summary_by_pillar) {
            labels.push(assesment_summary_by_pillar[s].pillar.name)
        }

        for(let relationship in relationships) {
            if(showRelationships.includes(relationship)) {
                const data = [];
                for(let s in assesment_summary_by_pillar) {
                    data.push(assesment_summary_by_pillar[s][relationship].toFixed(2));
                }

                datasets.push({
                   label: relationship
                ,  borderColor: documentStyle.getPropertyValue(relationships[relationship])
                ,  pointBackgroundColor: documentStyle.getPropertyValue(relationships[relationship])
                ,  pointBorderColor: documentStyle.getPropertyValue(relationships[relationship])
                ,  pointHoverBackgroundColor: textColor
                ,  pointHoverBorderColor: documentStyle.getPropertyValue(relationships[relationship])
                ,  data: data
                });
            }
        };

        const data = {            
            labels: labels,
            datasets: datasets
        };
        const options = {
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: textColorSecondary
                    },
                    suggestedMin: 0,
                    suggestedMax: 10
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [assesment_summary_by_pillar,showRelationships]);

    return (
        
            <Chart type="radar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
        
    )
}