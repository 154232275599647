import React from 'react';
import { useNavigate } from 'react-router-dom';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const Users = ({users}) => {

    const navigate = useNavigate();

    const onRowSelect = (event) => 
        navigate(`/assesments?candidateId=${event.data.id}`)
    ;

    const header = 
        <span className="text-xl text-900 font-bold">Coachees</span>
    ;

    const completedColumn = ({assesments_c}) => {
        let avgStart = 0;
        assesments_c.forEach(({completed})=>avgStart+=completed);
        avgStart = avgStart/assesments_c.length;
        return(
            avgStart.toFixed(2)
        )
    }

    return(
        <DataTable value={users} header={header} tableStyle={{ minWidth: '50rem' }}
            selectionMode="single" onRowSelect={onRowSelect}
            className="block"
        >
            <Column field="name" header="Coachee"/>
            <Column field="completed" header="Completeado" body={completedColumn}/>
        </DataTable>
    )
}