import React, { useState } from "react";
import { Rating } from 'primereact/rating';
import { useMutation, gql } from "@apollo/client"

export const SET_ANSWER = gql`
mutation SetAnswer($id: Int!, $value: Int!) {
  answer(id: $id, value: $value) {
   id
   value
   assesment {
     id
     status { code:id name color }
     completed
   }
  }
}
`

export const Answer = ({answer, disabled}) => {
    const [valueNew, setValue] = useState(answer.value);

    const [SetAnswer/*,SetAnswerReturn*/] = useMutation(SET_ANSWER
    , {
      onCompleted: (data =>{        
        setValue(data.answer.value);
        //console.info(data);
      }),
      onError: (error =>{
        alert(error)
        //console.error(error)
      })
    }
    );

    function updateValue (newValue) {      
      SetAnswer({
        variables: { 
          id: answer.id 
        , value: newValue
        }
      });
    }

    return(
      <div key={answer.id} className="flex pt-5 justify-content-between">
      <div>{answer.question.name}</div>
      <Rating className="mr-5" value={valueNew} onChange={(e) => updateValue(e.value)} cancel={false} stars={10} disabled={disabled||false}/>
      </div>    
    )
}