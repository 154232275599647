import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

//import * as serviceWorker from "./serviceWorker.js";

import { getConfig } from "./config";
import { Auth0Provider  } from '@auth0/auth0-react';
import {ApolloProviderWithAuth0 }from './components/ApolloProviderAuth0.tsx';


import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
//import 'primereact/resources/themes/lara-light-indigo/theme.css';
//import 'primereact/resources/themes/vela-purple/theme.css';
//import 'primereact/resources/themes/saga-purple/theme.css';
//import 'primereact/resources/themes/arya-purple/theme.css';
//import 'primereact/resources/themes/nano/theme.css';
import 'primereact/resources/themes/mira/theme.css';

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  //onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = createRoot(document.getElementById('root'));
root.render(
<Auth0Provider {...providerConfig} > 
  <ApolloProviderWithAuth0>
    <React.StrictMode>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </React.StrictMode>
  </ApolloProviderWithAuth0>
  </Auth0Provider>  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();