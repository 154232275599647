import react from 'react';
import { MultiSelect, MultiSelectChangeEvent  } from 'primereact/multiselect';
import { SelectButton, SelectButtonChangeEvent  } from 'primereact/selectbutton';
import { useState, useEffect } from 'react';

export const RelationshipToggle = ({selectedRelationships, onChange}) => {
    const relationships : string[] = [
        'Yo',
        'Demas',
        'Jefe',
        'Par',
        'Colaborador',
        'Familia',
    ];

    const [visibleRelationships, setVisibleRelationships] = useState<string[]>(
        selectedRelationships || relationships.filter(rel=> ['Yo','Jefe'].includes(rel))
    );

    useEffect(() => {
        setVisibleRelationships(selectedRelationships || relationships.filter(rel=> ['Yo','Jefe'].includes(rel)));
    }, [selectedRelationships,relationships]);

    const onRelationshipToggle = (event: MultiSelectChangeEvent) => {
        let selectedRelationship = event.value;
        
        let orderedSelectedRelationships = relationships
            .filter( rel => selectedRelationship.some( sRel => sRel === rel));

        setVisibleRelationships(orderedSelectedRelationships);
        if(onChange) onChange(orderedSelectedRelationships);
    };

    return(
    <MultiSelect 
        value={visibleRelationships} 
        options={relationships} 
        onChange={onRelationshipToggle} 
        className="w-full " 
        display="chip" 
    />
    );
}

export const RelationshipSelectButton = ({relationships, selectedRelationships, onChange}) => {

    const [visibleRelationships, setVisibleRelationships] = useState<string[]>(
        selectedRelationships || relationships.filter(rel=> ['Yo','Jefe'].includes(rel))
    );

    useEffect(() => {
        setVisibleRelationships(selectedRelationships || relationships.filter(rel=> ['Yo','Jefe'].includes(rel)));
    }, [relationships,selectedRelationships]);

    const onRelationshipChange = (event: SelectButtonChangeEvent) => {
        let selectedRelationship = event.value;

        let orderedSelectedRelationships = relationships
            .filter( rel => selectedRelationship.some( sRel => sRel === rel));

        setVisibleRelationships(orderedSelectedRelationships);
        if(onChange) onChange(orderedSelectedRelationships);

    };

    return(
    <SelectButton 
        value={visibleRelationships}
        options={relationships}
        onChange={onRelationshipChange} 
        className="w-full"         
        multiple 
    />
    );
}