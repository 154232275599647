import React from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery,useMutation, gql } from '@apollo/client';


import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner/index.js';
import { Button } from 'primereact/button';
      

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';



export const GET_LICENSEKEYS = gql`
query licenseKey ($licenseKey:String){
  license_key (licenseKey:$licenseKey){
    licenseKey
    id
    description
    candidate {
      id
      name
      email
    }
  }
}
`

 const RedeemButton = ({id,licenseKey,email}) => {
  const REDEEM_LICENSEKEYS = gql`
  mutation license_key_redeem ($licenseKey:String){
    license_key_mutation(licenseKey:$licenseKey) {
      id
      licenseKey
      description
      candidate {
        id
        name
        email
      }
    }
  }
  `;
  let navigate = useNavigate();
  const [redeemLicenseKey/*,redeemLicenseKeyReturn*/] = useMutation(REDEEM_LICENSEKEYS, {
    variables: {},
    onCompleted: (data) => {
      console.log(data);
      
      navigate(`/licenses/${data.license_key_mutation.id}`)
    },
  });

  function redeemLicenseKeyAction () {    
    redeemLicenseKey({
      variables: {         
        id:id
      , licenseKey: licenseKey
      , email: email
      }
    });
  }

  return <Button label="Redimir" severity="info" onClick={redeemLicenseKeyAction} />

}

export const RedeemPage = () => {
  const {licenseKey}=useParams();

  const {loading, error, data} = useQuery(GET_LICENSEKEYS, {
    variables: {
      licenseKey:licenseKey
    }
  });


 
  return (
      <QueryResult error={error} loading={loading} data={data} >
       <div>
      <p>Bienvenido a su evalución!</p>
       <p>Se le compartío este enlace para que pudiera reclamar la llave que se le envío</p>
       <h3>{data && data.license_key && data.license_key[0] && data.license_key[0].licenseKey}</h3>
    </div>
       
       {data && data.license_key && data.license_key[0] &&
       (
         data.license_key[0]?.candidate === null 
       ? <RedeemButton id={data.license_key[0].id} licenseKey={data.license_key[0].licenseKey} email="a.a@a" />
       : <p>Ya redimio esta llave!</p> 
       )}

      </QueryResult>
  );
};

export default withAuthenticationRequired(RedeemPage, {
  onRedirecting: () => <SpinnerContainer />,
});